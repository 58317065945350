import React from "react";
import Container from "@mui/material/Container";
import SectionHeader from "./SectionHeader";
import Button from "@mui/material/Button";

function SettingsUpgradeAccount(_props: any) {
    return <Container maxWidth="xs">
        <SectionHeader
            title={"Account upgraden"}
            subtitle={"Neem contact met ons op om uw account naar premium te upgraden. Klik op [Account upgraden] en verstuur een mailtje naar onze support afdeling."}
            size={4}
            textAlign="center"
        />
        <Button component={"a"}
                href={"mailto:support@etintell.eu?subject=ETIntell%20%7C%20Account%20upgraden&body=Beste%20ETIntell%20Support%2C%20%0A%0AIk%20wil%20mijn%20account%20upgraden%2C%20kunt%20u%20contact%20met%20ons%20opnemen%3F%0A"}
                variant="contained"
                color="primary" sx={{pt: 1, mt: 2}}>Account upgraden</Button>
    </Container>
}

export default SettingsUpgradeAccount;
