import {AuthContext, useAuth} from "../util/auth";
import {IDashboard} from "../ic3/AvailableDashboards";
import {ProfileSettings} from "../util/AvailableProfiles";

export enum AuthMatrixResult {
    CAN_USE,  // User has access to functionality
    CAN_VIEW,  // User does not have access, but sees a promo
    HIDDEN  // User does not have access, and the item is hidden from the UX.
}

export type PossibleAppURLs = "/"
    | "/zoeken"
    | IDashboard['url']
    | "/purchase/:plan"
    | "/admin-audit"
    | "/admin-app"
    | "/iccube-admin"
    | "/profile-ip/edit"
    | "/profile-ad/edit"
    | ProfileSettings['url']
    | "/settings/general"
    | "/settings/password"
    | "/settings/alert"
    | "/settings/activate-account"
    | "/settings/upgrade-account"
    | "/expert-app"
    ;

export default function useAuthMatrix(url: PossibleAppURLs): AuthMatrixResult {
    const auth = useAuth();
    return authMatrix(auth, url);
}

export function authMatrix(auth: AuthContext, url: PossibleAppURLs) {

    const role = auth.user.app_metadata.role as string;

    const checkedRole =
        role === "unpaid"
        || role === "basic"
        || role === "premium"
        || role === "expert"
        || role === "administrator"
        || role === "support"
        ? role
        : 'unpaid';

    switch (checkedRole) {
        case "unpaid":
            switch (url) {
                case "/": return AuthMatrixResult.CAN_USE;
                case "/zoeken": return AuthMatrixResult.CAN_VIEW;
                case "/profile-ad/edit": return AuthMatrixResult.CAN_VIEW;
                case "/profile-ip/edit": return AuthMatrixResult.CAN_VIEW;
                case "/profile-ad": return AuthMatrixResult.CAN_VIEW;
                case "/profile-ip": return AuthMatrixResult.CAN_VIEW;
                case "/settings/general": return AuthMatrixResult.CAN_USE;
                case "/settings/password": return AuthMatrixResult.CAN_USE;
                case "/aanbestedingskalender-ad": return AuthMatrixResult.CAN_VIEW;
                case "/marktverkenning-ad": return AuthMatrixResult.CAN_VIEW;
                case "/settings/activate-account": return AuthMatrixResult.CAN_USE;
                case "/marktverkenning-ip": return AuthMatrixResult.CAN_VIEW;
                case "/commercieel-plan-ip": return AuthMatrixResult.CAN_VIEW;
                case "/settings/alert": return AuthMatrixResult.CAN_VIEW;
                case "/alert-ip": return AuthMatrixResult.CAN_VIEW;
            }
            return AuthMatrixResult.HIDDEN;
        case "basic":
            switch (url) {
                case "/": return AuthMatrixResult.CAN_USE;
                case "/zoeken": return AuthMatrixResult.CAN_USE;
                case "/profile-ad/edit": return AuthMatrixResult.CAN_VIEW;
                case "/profile-ip/edit": return AuthMatrixResult.CAN_USE;
                case "/profile-ad": return AuthMatrixResult.CAN_VIEW;
                case "/profile-ip": return AuthMatrixResult.CAN_USE;
                case "/marktverkenning-ip": return AuthMatrixResult.CAN_USE;
                case "/commercieel-plan-ip": return AuthMatrixResult.CAN_VIEW;
                case "/alert-ip": return AuthMatrixResult.CAN_USE;
                case "/settings/alert": return AuthMatrixResult.CAN_USE;
                case "/aanbestedingskalender-ad": return AuthMatrixResult.CAN_VIEW;
                case "/marktverkenning-ad": return AuthMatrixResult.CAN_VIEW;
                case "/settings/general": return AuthMatrixResult.CAN_USE;
                case "/settings/activate-account": return AuthMatrixResult.HIDDEN;
                case "/settings/password": return AuthMatrixResult.CAN_USE;
                case "/settings/upgrade-account": return AuthMatrixResult.CAN_USE;
            }
            return AuthMatrixResult.HIDDEN;
        case "premium":
            switch (url) {
                case "/": return AuthMatrixResult.CAN_USE;
                case "/zoeken": return AuthMatrixResult.CAN_USE;
                case "/profile-ad/edit": return AuthMatrixResult.CAN_USE;
                case "/profile-ip/edit": return AuthMatrixResult.CAN_USE;
                case "/profile-ad": return AuthMatrixResult.CAN_USE;
                case "/profile-ip": return AuthMatrixResult.CAN_USE;
                case "/marktverkenning-ip": return AuthMatrixResult.CAN_USE;
                case "/commercieel-plan-ip": return AuthMatrixResult.CAN_USE;
                case "/alert-ip": return AuthMatrixResult.CAN_USE;
                case "/settings/alert": return AuthMatrixResult.CAN_USE;
                case "/aanbestedingskalender-ad": return AuthMatrixResult.CAN_USE;
                case "/marktverkenning-ad": return AuthMatrixResult.CAN_USE;
                case "/settings/general": return AuthMatrixResult.CAN_USE;
                case "/settings/password": return AuthMatrixResult.CAN_USE;
            }
            return AuthMatrixResult.HIDDEN;
        case "expert":
            switch (url) {
                case "/": return AuthMatrixResult.CAN_USE;
                case "/zoeken": return AuthMatrixResult.CAN_USE;
                case "/profile-ad/edit": return AuthMatrixResult.CAN_USE;
                case "/profile-ip/edit": return AuthMatrixResult.CAN_USE;
                case "/profile-ad": return AuthMatrixResult.CAN_USE;
                case "/profile-ip": return AuthMatrixResult.CAN_USE;
                case "/marktverkenning-ip": return AuthMatrixResult.CAN_USE;
                case "/commercieel-plan-ip": return AuthMatrixResult.CAN_USE;
                case "/alert-ip": return AuthMatrixResult.CAN_USE;
                case "/settings/alert": return AuthMatrixResult.CAN_USE;
                case "/aanbestedingskalender-ad": return AuthMatrixResult.CAN_USE;
                case "/marktverkenning-ad": return AuthMatrixResult.CAN_USE;
                case "/settings/general": return AuthMatrixResult.CAN_USE;
                case "/settings/password": return AuthMatrixResult.CAN_USE;
                case "/expert-app": return AuthMatrixResult.CAN_USE;
            }
            return AuthMatrixResult.HIDDEN;
        case "administrator":
            return AuthMatrixResult.CAN_USE;
        case "support":
            // Support is not implemented for now
            return AuthMatrixResult.HIDDEN;
    }

}