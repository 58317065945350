import React, {useEffect, useState} from "react";
import Alert from "@mui/material/Alert";
import Meta from "../components/Meta";
import PageLoader from "../components/PageLoader";
import {useAuth} from "../util/auth";
import {useRouter} from "../util/Router";
import {redirectToCheckout} from "../util/stripe";
import {FormAlert} from "../util/util";

export default function PurchasePage() {
    const router = useRouter();
    const auth = useAuth();
    const [formAlert, setFormAlert] = useState<FormAlert>();

    useEffect(() => {
        if (auth.user.planIsActive) {
            // If user already has an active plan
            // then take them to Stripe billing
            router.push("/settings/billing");
        } else {
            // Otherwise go to checkout
            redirectToCheckout(router.query.plan).catch((error) => {
                setFormAlert({
                    type: "error",
                    message: error.message,
                });
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Meta title="Purchase"/>
            <PageLoader>
                {formAlert && (
                    <Alert severity={formAlert.type} style={{maxWidth: "500px"}}>
                        {formAlert.message}
                    </Alert>
                )}
            </PageLoader>
        </>
    );
}
