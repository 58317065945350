import React from "react";
import Meta from "../components/Meta";
import SectionHeader from "../components/SectionHeader";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";

export default function ReleaseNotes() {

    const [result, setResult] = React.useState("");

    React.useEffect(() => {
        fetch("/changes.html")
            .then(response => response.text())
            .then(result => setResult(result))
            .catch(error => setResult(error.msg));
    });

    return (
        <>
            <Meta title="Release Notes"/>
            <Container maxWidth="md">

                <SectionHeader
                    title={"Release Notes"}
                    subtitle={""}
                    size={2}
                    textAlign="center"
                />

                {
                    result
                        ? <div dangerouslySetInnerHTML={{__html: result}}/>
                        : <CircularProgress size={20}/>
                }

            </Container>
        </>
    );
}
