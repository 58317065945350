import React, {useState} from "react";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useForm} from "react-hook-form";
import {useAuth} from "../util/auth";
import Container from "@mui/material/Container";

function SettingsPassword(props: any) {
    const auth = useAuth();
    const [pending, setPending] = useState(false);

    const {register, handleSubmit, formState: {errors}, reset, getValues} = useForm();

    const onSubmit = (data: any) => {
        // Show pending indicator
        setPending(true);

        auth
            .updatePassword(data.pass)
            .then(() => {
                // Clear form
                reset();
                // Set success status
                props.onStatus({
                    type: "success", message: "Your password has been updated",
                });
            })
            .catch((error: any) => {
                if (error.code === "auth/requires-recent-login") {
                    // Update state to show re-authentication modal
                    props.onStatus({
                        type: "requires-recent-login", // Resubmit after reauth flow
                        callback: () => onSubmit({pass: data.pass}),
                    });
                } else {
                    // Set error status
                    props.onStatus({
                        type: "error", message: error.message,
                    });
                }
            })
            .finally(() => {
                // Hide pending indicator
                setPending(false);
            });
    };

    return (<Container maxWidth="xs">
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container={true} spacing={2}>
                <Grid size={{xs: 12}}>
                    <TextField
                        variant="outlined"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        error={!!errors.pass}
                        helperText={errors.pass && String(errors.pass.message)}
                        fullWidth={true}
                        {...register("pass", {
                            required: "Please enter a password",
                        })}
                    />
                </Grid>
                <Grid size={{xs: 12}}>
                    <TextField
                        variant="outlined"
                        type="password"
                        label="Confirm New Password"
                        placeholder="Confirm Password"
                        error={!!errors.confirmPass}
                        helperText={errors.confirmPass && String(errors.confirmPass.message)}
                        fullWidth={true}
                        {...register("confirmPass", {
                            required: "Please enter your new password again", validate: (value) => {
                                if (value === getValues().pass) {
                                    return true;
                                } else {
                                    return "This doesn't match your password";
                                }
                            },
                        })}
                    />
                </Grid>
                <Grid size={{xs: 12}}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={pending}
                        fullWidth={true}
                    >
                        {!pending && <span>Save</span>}

                        {pending && <CircularProgress size={28}/>}
                    </Button>
                </Grid>
            </Grid>
        </form>
    </Container>);
}

export default SettingsPassword;
