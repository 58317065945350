import React, {useMemo} from "react";
import {createTheme, ThemeProvider as MuiThemeProvider, useTheme,} from '@mui/material/styles';
import * as colors from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import "@fontsource/inter/latin-400.css"
import "@fontsource/inter/latin-500.css"
import "@fontsource/inter/latin-600.css"
import "@fontsource/inter/latin-700.css"
import "@fontsource/inter/latin-800.css"

// Force loading the fonts
document.fonts.load("400 12px Inter");
document.fonts.load("500 12px Inter");
document.fonts.load("600 12px Inter");
document.fonts.load("700 12px Inter");
document.fonts.load("800 12px Inter");

const themeConfig: any = {
    // Light theme
    light: {
        palette: {
            mode: "light",
            primary: {
                main: "#262262",
            },
            secondary: {
                main: "#1e6fdb"
            },
            background: {
                // Background for <body>
                // and <Section color="default">
                default: "#fff",
                // Background for elevated
                // components (<Card>, etc)
                paper: "#fbfcfe",
            },
        },
    },

    // Dark theme
    dark: {
        palette: {
            mode: "dark",
            primary: {
                // Same as in light but we could
                // adjust color hue if needed
                main: colors.indigo["500"],
            },
            secondary: {
                main: colors.pink["500"],
            },
            background: {
                default: colors.grey["900"],
                paper: colors.grey["800"],
            },
        },
    },

    // Values for both themes
    common: {
        typography: {

            fontFamily: "Inter",
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
            fontSize: 14,

            h1: {
                fontWeight: 800,
                lineHeight: 64 / 48,
                fontSize: pxToRem(32),
                ...responsiveFontSizes({sm: 40, md: 44, lg: 48})
            },
            h2: {
                fontWeight: 700,
                lineHeight: 1.5,
                fontSize: pxToRem(24),
                ...responsiveFontSizes({sm: 26, md: 30, lg: 32})
            },
            h3: {
                fontWeight: 700,
                lineHeight: 1.5,
                fontSize: pxToRem(20),
                ...responsiveFontSizes({sm: 20, md: 24, lg: 24})
            },
            h4: {
                fontWeight: 700,
                lineHeight: 1.5,
                fontSize: pxToRem(18),
                ...responsiveFontSizes({sm: 19, md: 20, lg: 20})
            },
            h5: {
                fontWeight: 700,
                lineHeight: 28 / 18,
                fontSize: pxToRem(17),
                ...responsiveFontSizes({sm: 18, md: 18, lg: 18})
            },
            h6: {
                fontWeight: 700,
                lineHeight: 28 / 18,
                fontSize: pxToRem(17),
                ...responsiveFontSizes({sm: 18, md: 18, lg: 18})
            },
            subtitle1: {
                fontWeight: 600,
                lineHeight: 1.5,
                fontSize: pxToRem(16)
            },
            subtitle2: {
                fontWeight: 600,
                lineHeight: 22 / 14,
                fontSize: pxToRem(14)
            },
            body1: {
                lineHeight: 22 / 14,
                fontSize: pxToRem(14)
            },
            body2: {
                lineHeight: 22 / 14,
                fontSize: pxToRem(14)
            },
            caption: {
                lineHeight: 1.5,
                fontSize: pxToRem(12)
            },
            overline: {
                fontWeight: 700,
                lineHeight: 1.5,
                fontSize: pxToRem(12),
                textTransform: 'uppercase'
            },
            button: {
                fontWeight: 700,
                lineHeight: 24 / 14,
                fontSize: pxToRem(14),
                textTransform: 'unset'
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1300,
                xl: 1920,
            },
        },
        // Override component styles
        components: {

            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        padding: '4px',
                        gap: '8px',

                        '& .MuiListItemIcon-root': {
                            minWidth: '40px'
                        },

                        '& > .MuiSvgIcon-root': {
                            fontSize: '20px'
                        }
                    }
                }
            },

            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        paddingLeft: '4px'
                    }
                }
            }

        },
    },
};

export function pxToRem(value: number) {
    return `${value / 16}rem`;
}

export function responsiveFontSizes({sm, md, lg}: Record<string, number>) {
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg),
        },
    };
}

function getTheme(name: string) {
    // Create MUI theme from themeConfig
    return createTheme({
        ...themeConfig[name],
        // Merge in common values
        ...themeConfig.common,
        components: {
            // Merge overrides
            ...(themeConfig[name] && themeConfig[name].components),
            ...(themeConfig.common && themeConfig.common.components),
        },
    });
}

export const ThemeProvider = (props: any) => {
    // Get system dark mode preference
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
        noSsr: true,
    });

    // Get MUI theme object
    const mode = prefersDarkMode ? "dark" : "light";
    const theme = useMemo(() => getTheme(mode), [mode]);

    return (<MuiThemeProvider theme={theme}>
        {/* Set global MUI styles */}
        <CssBaseline/>
        {props.children}
    </MuiThemeProvider>);
};

// Hook for detecting dark mode and toggling between light/dark
// More convenient than reading theme.palette.type from useTheme
export function useDarkMode() {
    // Get current Material UI theme
    const theme = useTheme();
    // Check if it is the dark theme
    const isDarkMode = theme.palette.mode === "dark";
    // Return object containing dark mode value and toggle function
    return {isDarkMode: isDarkMode};
}
