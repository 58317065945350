import React from "react";
import Container from "@mui/material/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";
import {EtiLogo} from "./EtiLogo";


interface SignupOptions {
    showFooter?: boolean;
    title: string;
    buttonAction: string;
    signinText?: string;
    signinAction?: string;
    signinPath?: string;
    showAgreement?: boolean;
    termsURL?: string;
    privacyPolicyURL?: string;
    signupAction?: string;
    signupPath?: string;
    forgotPassAction?: string;
    forgotPassPath?: string;
}

const optionsByType: Record<"signup" | "signin" | "forgotpass" | "changepass", SignupOptions> = {
    signup: {
        // Top Title
        title: "Get yourself an account",
        // Button text
        buttonAction: "Sign up",
        // Footer text and links
        showFooter: true,
        signinText: "Already have an account?",
        signinAction: "Sign in",
        signinPath: "/auth/signin",
        // Terms and privacy policy agreement
        showAgreement: true,
        termsURL: "https://www.etintell.eu/wp-content/uploads/2024/06/ETIntell-Algemene-voorwaarden.pdf",
        privacyPolicyURL: "https://www.etintell.eu/wp-content/uploads/2024/06/ETIntell-Disclaimer.pdf",
    },
    signin: {
        title: "Welcome back",
        buttonAction: "Sign in",
        showFooter: true,
        signupAction: "Create an account",
        signupPath: "/auth/signup",
        forgotPassAction: "Forgot Password?",
        forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
        title: "Get a new password",
        buttonAction: "Reset password",
        showFooter: true,
        signinText: "Remember it after all?",
        signinAction: "Sign in",
        signinPath: "/auth/signin",
    },
    changepass: {
        title: "Choose a new password",
        buttonAction: "Change password",
    },
};

function AuthSection(props: {
    type: keyof typeof optionsByType;
    bgColor: string;
    size: string;
    bgImage: string;
    bgImageOpacity: number;
    providers: any;
    afterAuthPath: any;
}) {

    // Options by auth type


    // Ensure we have a valid auth type
    const type = optionsByType[props.type] ? props.type : "signup";

    // Get options object for current auth type
    const options = optionsByType[type];

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container maxWidth="xs" sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <EtiLogo height={'150px'}/>
                <SectionHeader
                    title={options.title}
                    subtitle=""
                    size={4}
                    textAlign="center"
                />
                <Auth
                    type={type}
                    buttonAction={options.buttonAction}
                    providers={props.providers}
                    afterAuthPath={props.afterAuthPath}
                    key={type}
                />

                {options.showFooter && <AuthFooter type={type} {...options} />}
            </Container>
        </Section>
    );
}

export default AuthSection;
