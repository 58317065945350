import React from "react";
import PricingPage from "./pricing";
import AuthPage from "./AuthPage";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import "./../util/analytics";
import {AuthProvider} from "../util/auth";
import {QueryClientProvider} from "../util/db";
import AdminPage from "./icAdmin";
import ConfirmSignupPage from "./signup";
import {ThemeProvider} from "../util/theme";
import ProfilePage from "./ProfilePage";
import {Router} from "../util/Router";
import {Route, Routes} from "react-router-dom";
import SettingsPage from "./SettingsPage";
import DashboardPage from "./DashboardPage";
import {DASHBOARDS_AD, DASHBOARDS_IP} from "../ic3/AvailableDashboards";
import PageWithSidebarAndAuthentication from "./PageWithSidebarAndAuthentication";
import {ProfileProvider} from "../util/profile";
import HomePage from "./HomePage";
import EditProfilePage from "./EditProfilePage";
import {PROFILES} from "../util/AvailableProfiles";
import {DashboardsDiv} from "../ic3/DashboardsDiv";
import UnsubscribePage from "./unsubscribe";
import ReleaseNotes from "./ReleaseNotes";

function App() {

    return (<QueryClientProvider>
        <ThemeProvider>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={
                            <PageWithSidebarAndAuthentication>
                                <HomePage/>
                            </PageWithSidebarAndAuthentication>
                        }/>

                        <Route path="/zoeken" element={
                            <PageWithSidebarAndAuthentication>
                                <DashboardsDiv appPath={"shared:/ETIntell02/basic"}/>
                            </PageWithSidebarAndAuthentication>
                        }/>

                        {
                            DASHBOARDS_IP.map(i =>
                                <Route path={i.url} key={i.url} element={
                                    <PageWithSidebarAndAuthentication>
                                        <ProfileProvider>
                                            <DashboardPage dashboard={i} neededProfile={"profile-ip"}/>
                                        </ProfileProvider>
                                    </PageWithSidebarAndAuthentication>
                                 }/>
                            )
                        }

                        {
                            DASHBOARDS_AD.map(i =>
                                <Route path={i.url} key={i.url} element={
                                    <PageWithSidebarAndAuthentication>
                                        <ProfileProvider>
                                            <DashboardPage dashboard={i} neededProfile={"profile-ad"}/>
                                        </ProfileProvider>
                                    </PageWithSidebarAndAuthentication>
                                 }/>
                            )
                        }

                        {
                            PROFILES.map(i =>
                                <Route path={i.url} key={i.id} element={
                                    <PageWithSidebarAndAuthentication>
                                        <ProfileProvider>
                                            <ProfilePage
                                                profileSettings={i}
                                            />
                                        </ProfileProvider>
                                    </PageWithSidebarAndAuthentication>
                                 }/>
                            )
                        }

                        {
                            PROFILES.map(i =>
                                <Route path={"/" + i.id + "/edit"} key={i.id} element={
                                    <PageWithSidebarAndAuthentication>
                                        <ProfileProvider>
                                            <EditProfilePage profileSettings={i}/>
                                        </ProfileProvider>
                                    </PageWithSidebarAndAuthentication>
                                 }/>
                            )
                        }

                        <Route path="/pricing" element={<PricingPage/>}/>

                        <Route path="/iccube-admin" element={
                            <PageWithSidebarAndAuthentication>
                                <AdminPage/>
                            </PageWithSidebarAndAuthentication>
                         }/>

                        <Route path="/admin-app" element={
                            <PageWithSidebarAndAuthentication>
                                <DashboardsDiv appPath={"shared:/ETIntell02/admin"}/>
                            </PageWithSidebarAndAuthentication>
                         }/>

                        <Route path="/expert-app" element={
                            <PageWithSidebarAndAuthentication>
                                <DashboardsDiv appPath={"shared:/ETIntell02/expert"}/>
                            </PageWithSidebarAndAuthentication>
                         }/>

                        <Route path="/admin-audit" element={
                            <PageWithSidebarAndAuthentication>
                                <DashboardsDiv appPath={"shared:/ETIntell02/audit"}/>
                            </PageWithSidebarAndAuthentication>
                         }/>

                        <Route path="/auth/:type" element={<AuthPage/>}/>

                        <Route path="/settings/:section" element={
                            <PageWithSidebarAndAuthentication>
                                <SettingsPage/>
                            </PageWithSidebarAndAuthentication>
                         }/>

                        <Route path="/confirm-signup" element={<ConfirmSignupPage/>}/>

                        <Route path="/purchase/:plan" element={
                            <PageWithSidebarAndAuthentication>
                                <PurchasePage/>
                            </PageWithSidebarAndAuthentication>
                         }/>

                        <Route path="/unsubscribe" element={<UnsubscribePage/>}/>

                        <Route path="/release-notes" element={
                            <PageWithSidebarAndAuthentication>
                                <ReleaseNotes/>
                            </PageWithSidebarAndAuthentication>
                         }/>

                        <Route element={<NotFoundPage/>}/>
                    </Routes>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    </QueryClientProvider>);
}

export default App;
