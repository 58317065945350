import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Link} from "react-router-dom";
import {ProTag} from "./MenuListItemAuth";
import {Box} from "@mui/material";
import {authMatrix, AuthMatrixResult, PossibleAppURLs} from "../hooks/useAuthMatrix";
import {AuthContext, useAuth} from "../util/auth";

function SettingsNav(props: any) {

    const auth = useAuth();

    return (<Tabs
        value={props.activeKey}
        indicatorColor="primary"
        textColor="primary"
        centered={true}
    >
        {[  // The tabs must be rendered on the first pass
            getTab("/settings/general", "General", "general", auth),
            getTab("/settings/password", "Password", "password", auth),
            getTab("/settings/alert", "Alerts", "alert", auth),
            getTab("/settings/activate-account", "Account activeren", "activate-account", auth),
            getTab("/settings/upgrade-account", "Account upgraden", "upgrade-account", auth)
        ]}
        {/* Uncomment when activating pricing */}
        {/*<Tab*/}
        {/*  component={Link}*/}
        {/*  to="/settings/billing"*/}
        {/*  label="Billing"*/}
        {/*  value="billing"*/}
        {/*/>*/}
    </Tabs>);
}

function getTab(to: PossibleAppURLs, label: string, value: string, auth: AuthContext) {

    const alertPermissions = authMatrix(auth, to);

    if (alertPermissions === AuthMatrixResult.HIDDEN) {
        return undefined;
    }

    return <Tab
        key={value}
        component={Link}
        to={to}
        label={label}
        value={value}
        icon={alertPermissions === AuthMatrixResult.CAN_VIEW ?
            <Box sx={{marginLeft: '4px'}}><ProTag/></Box> : undefined}
        iconPosition="end"
    />

}

export default SettingsNav;
