import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckIcon from "@mui/icons-material/Check";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: theme.spacing(3),
    },
    price: {
        display: "flex",
        alignItems: "baseline",
    },
    listItem: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    perkIcon: {
        minWidth: 34,
        color: theme.palette.success.main,
    },
}));

function PricingSection(props: any) {
    const classes = useStyles();

    /* Uncomment when activating Stripe */
    // const auth = useAuth();

    const plans = [
        {
            id: "premium",
            name: "Account activeren",
            price: "-",
            perks: [
                "IP: Marktverkenning, Commerciëel Plan, Alert",
                "AD: Marktverkenning, Aanbestedingskalender",
                "Premium toegang tot nieuwste ontwikkelingen"
            ],
            description: ""
        }
        // Add more plans here.
    ];

    return (
        <Section
            bgColor={props.bgColor}
            size={props.size}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
        >
            <Container>
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    size={4}
                    textAlign="center"
                />
                <Grid container={true} justifyContent="center" spacing={4}>
                    {plans.map((plan, index) => (
                        <Grid size={{xs: 12, md: 4}} key={index}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h6" component="h2">
                                        {plan.name}
                                    </Typography>
                                    {/*<Box className={classes.price} mt={1}>*/}
                                    {/*    <Typography variant="h3">€{plan.price}</Typography>*/}
                                    {/*    <Typography variant="h4" color="textSecondary">*/}
                                    {/*        /mo*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}

                                    {plan.description && (
                                        <Box mt={2}>
                                            <Typography component="p" color="textSecondary">
                                                {plan.description}
                                            </Typography>
                                        </Box>
                                    )}

                                    {plan.perks && (
                                        <Box mt={1}>
                                            <List aria-label="perks">
                                                {plan.perks.map((perk, index) => (
                                                    <ListItem
                                                        className={classes.listItem}
                                                        disableGutters={true}
                                                        key={index}
                                                    >
                                                        <ListItemIcon className={classes.perkIcon}>
                                                            <CheckIcon/>
                                                        </ListItemIcon>
                                                        <ListItemText>{perk}</ListItemText>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Box>
                                    )}

                                    <Typography>Klik op [Activeren] en verstuur een mail naar support.</Typography>
                                    <Button component={"a"}
                                            href={"mailto:support@etintell.eu?subject=ETIntell%20%7C%20Account%20activeren&body=Beste%20ETIntell%20Support%2C%0A%0AKunt%20u%20mijn%20account%20activeren%3F%0AWe%20hebben%20een%20abonnement.%0A"}
                                            variant="contained"
                                            color="primary" sx={{pt: 1, mt: 2}}>Account activeren</Button>

                                    {/* Uncomment when activating Stripe */}
                                    {/*<Box mt="auto" pt={3}>*/}
                                    {/*    <Button*/}
                                    {/*        component={Link}*/}
                                    {/*        to={*/}
                                    {/*            auth.user*/}
                                    {/*                ? `/purchase/${plan.id}`*/}
                                    {/*                : `/auth/signup?next=/purchase/${plan.id}`*/}
                                    {/*        }*/}
                                    {/*        variant="contained"*/}
                                    {/*        color="primary"*/}
                                    {/*        size="large"*/}
                                    {/*        fullWidth={true}*/}
                                    {/*    >*/}
                                    {/*        Choose*/}
                                    {/*    </Button>*/}
                                    {/*</Box>*/}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Section>
    );
}

export default PricingSection;
