import useAuthMatrix, {AuthMatrixResult, PossibleAppURLs} from "../hooks/useAuthMatrix";
import React from "react";
import FeatureRequiresPremium from "../pages/FeatureRequiresPremium";
import {useRouter} from "./Router";

interface Props {
    children: React.JSX.Element;
}

export default function RequireCorrectPermissions(props: Props) {

    const router = useRouter();
    const usage = useAuthMatrix(router.pathname as PossibleAppURLs);

    if (usage === AuthMatrixResult.CAN_VIEW) {
        return <FeatureRequiresPremium/>
    }

    if (usage === AuthMatrixResult.CAN_USE) {
        return props.children;
    }

    return null;

}