import {useCallback, useState} from "react";
import {FormAlert} from "../util/util";

export default function useDbUpdate(succesText: string) {
    const [formAlert, setFormAlert] = useState<FormAlert>();
    const [loading, setLoading] = useState(false);

    const handle = useCallback((p: Promise<any>) => {
        p.then(() => {
            setFormAlert({type: "success", message: succesText});
        }).catch((error: Error) => {
            setFormAlert({type: "error", message: error.message});
            setLoading(false);
        });
    }, [succesText]);

    return {formAlert, loading, handle};
}