import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Tooltip} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {useAuth} from "../util/auth";

interface Props {

}


export default function FeatureRequiresPremium(_props: Props) {

    const auth = useAuth();
    const role = auth.user.app_metadata.role;

    return <Box display={'flex'} height={'100%'} alignItems={'center'} flexDirection={'column'}
                justifyContent={'center'} gap={4} mb={6}>
        <Box sx={{
            borderRadius: '50%', background: "#fedb6f", padding: '16px',
        }}
             display={'flex'} flexDirection={'row'} gap={0.5} alignItems={'center'}>
            <Tooltip title={"Premium feature"}>
                <AutoAwesomeIcon sx={{fontSize: '36px'}}/>
            </Tooltip>
        </Box>
        <Typography>
            Om dit onderdeel te gebruiken heb je een premium account nodig
        </Typography>
        {
            role === "basic" ? <Button component={Link} to={'/settings/upgrade-account'}
                                       variant={'contained'}>Account upgraden</Button>
                : <Button component={Link} to={'/settings/activate-account'}
                          variant={'contained'}>Account activeren</Button>
        }
    </Box>

}