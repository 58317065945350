import React, {useState} from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Section from "./Section";
import ReauthModal from "./ReauthModal";
import SettingsNav from "./SettingsNav";
import SettingsGeneral from "./SettingsGeneral";
import SettingsPassword from "./SettingsPassword";
import {useAuth} from "../util/auth";
import SettingsPricing from "./SettingsActivateAccount";
import SettingsAlert from "./SetttingsAlert";
import {FormAlert} from "../util/util";
import SettingsUpgradeAccount from "./SettingsUpgradeAccount";

function SettingsSection(props: any) {
    const auth = useAuth();
    const [formAlert, setFormAlert] = useState<FormAlert>();

    // State to control whether we show a re-authentication flow
    // Required by some security sensitive actions, such as changing password.
    const [reauthState, setReauthState] = useState<any>({
        show: false,
    });

    const section = props.section;

    // Handle status of type "success", "error", or "requires-recent-login"
    // We don't treat "requires-recent-login" as an error as we handle it
    // gracefully by taking the user through a re-authentication flow.
    const handleStatus = ({type, message, callback}: any) => {
        if (type === "requires-recent-login") {
            // First clear any existing message
            setFormAlert(undefined);
            // Then update state to show re-authentication modal
            setReauthState({
                show: true, // Failed action to try again after reauth
                callback: callback,
            });
        } else {
            // Display message to user (type is success or error)
            setFormAlert({
                type: type, message: message,
            });
        }
    };

    return (<Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
    >
        {reauthState.show && (<ReauthModal
            callback={reauthState.callback}
            provider={auth.user.providers[0]}
            onDone={() => setReauthState({show: false})}
        />)}

        <SettingsNav activeKey={section}/>
        <Box mt={5}>

            {formAlert && (<Box mb={4}>
                <Alert severity={formAlert.type}>{formAlert.message}</Alert>
            </Box>)}

            {section === "general" && <SettingsGeneral onStatus={handleStatus}/>}

            {section === "password" && (<SettingsPassword onStatus={handleStatus}/>)}

            {section === "alert" && <SettingsAlert/>}

            {/* Uncomment when activating Stripe. */}
            {/*{section === "billing" && <SettingsBilling onStatus={handleStatus}/>}*/}

            {section === "activate-account" && <SettingsPricing onStatus={handleStatus}/>}

            {section === "upgrade-account" && <SettingsUpgradeAccount onStatus={handleStatus}/>}

        </Box>
    </Section>);
}

export default SettingsSection;
