import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

// Use this plugin for debugging purposes.
// const myPlugin = {
//     name: 'my-custom-plugin',
//     page: ({payload}: any) => {
//         console.log('page view fired', payload)
//     },
//     track: ({payload}: any) => {
//         console.log('track event payload', payload)
//     }
// }


// Initialize analytics and plugins
// Documentation: https://getanalytics.io
const analytics = Analytics({
    app: 'ETIntell',
    debug: process.env.NODE_ENV !== "production",
    plugins: [
        googleAnalytics({
            measurementIds: [process.env.REACT_APP_GA_MEASUREMENT_ID],
        })
    ],
});

export default analytics;
