import React from "react";
import Meta from "./../components/Meta";
import Box from "@mui/material/Box";
import useCustomHeaders from "../ic3/useCustomHeaders";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import {RequireAuthentication} from "../util/auth";
import RequireCorrectPermissions from "../util/RequireCorrectPermissions";

interface Props {
    children: React.JSX.Element;
}

export default function PageWithSidebarAndAuthentication(props: Props) {

    useCustomHeaders();

    return (
        <RequireAuthentication>
            <>
                <Meta title="Dashboard"/>
                <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                    <Header/>
                    <Sidebar/>
                    <Box
                        component="main"
                        className="MainContent"
                        sx={{
                            px: 0,
                            pt: {
                                xs: 'calc(12px + var(--Header-height))',
                                sm: 'calc(12px + var(--Header-height))',
                                md: 3,
                            },
                            pb: {
                                xs: 2,
                                sm: 2,
                                md: 3,
                            },
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 0,
                            height: '100dvh',
                            gap: 1,
                            overflowY: 'auto'
                        }}
                    >
                        <RequireCorrectPermissions>
                            {props.children}
                        </RequireCorrectPermissions>
                    </Box>
                </Box>
            </>
        </RequireAuthentication>
    );
}
