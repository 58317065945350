import React, {useState} from "react";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useForm} from "react-hook-form";
import {useAuth} from "../util/auth";
import Container from "@mui/material/Container";

function SettingsGeneral(props: any) {
    const auth = useAuth();
    const [pending, setPending] = useState(false);

    const {register, handleSubmit, formState: {errors}} = useForm();

    const onSubmit = (data: any) => {
        // Show pending indicator
        setPending(true);

        return auth
            .updateProfile(data)
            .then(() => {
                // Set success status
                props.onStatus({
                    type: "success",
                    message: "Your profile has been updated",
                });
            })
            .catch((error: any) => {
                if (error.code === "auth/requires-recent-login") {
                    props.onStatus({
                        type: "requires-recent-login",
                        // Resubmit after reauth flow
                        callback: () => onSubmit(data),
                    });
                } else {
                    // Set error status
                    props.onStatus({
                        type: "error",
                        message: error.message,
                    });
                }
            })
            .finally(() => {
                // Hide pending indicator
                setPending(false);
            });
    };

    return (
        <Container maxWidth="xs">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container={true} spacing={2}>
                    <Grid size={{xs: 12}}>
                        <TextField
                            variant="outlined"
                            type="text"
                            label="Name"
                            placeholder="Name"
                            defaultValue={auth.user.name}
                            error={!!errors.name}
                            helperText={errors.name && String(errors.name.message)}
                            fullWidth={true}
                            {...register("name", {
                                required: "Please enter your name",
                            })}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <TextField
                            variant="outlined"
                            type="email"
                            label="Email"
                            placeholder="user@example.com"
                            defaultValue={auth.user.email}
                            error={!!errors.email}
                            helperText={errors.email && String(errors.email.message)}
                            fullWidth={true}
                            {...register("email", {
                                required: "Please enter your email",
                            })}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            disabled={pending}
                            fullWidth={true}
                        >
                            {!pending && <span>Save</span>}

                            {pending && <CircularProgress size={28}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default SettingsGeneral;
