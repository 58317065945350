import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useForm} from "react-hook-form";
import {makeStyles} from "@mui/styles";
import AuthSocial from "./AuthSocial";
import {useAuth} from "../util/auth";

const useStyles = makeStyles(() => ({
    content: {
        paddingBottom: 24,
    },
}));

function ReauthModal(props: any) {
    const classes = useStyles();

    const auth = useAuth();
    const [pending, setPending] = useState<any>(false);
    const [formAlert, setFormAlert] = useState<any>(null);

    const {register, handleSubmit, formState: {errors}} = useForm();

    const onSubmit = (data: any) => {
        const {pass} = data;
        setPending(true);

        auth
            .signin(auth.user.email, pass)
            .then(() => {
                // Call failed action that originally required reauth
                props.callback();
                // Let parent know we're done so they can hide modal
                props.onDone();
            })
            .catch((error: any) => {
                // Hide pending indicator
                setPending(false);
                // Show error alert message
                setFormAlert({
                    type: "error",
                    message: error.message,
                });
            });
    };

    return (
        <Dialog open={true} onClose={props.onDone}>
            <DialogTitle>Please sign in again to complete this action</DialogTitle>
            <DialogContent className={classes.content}>
                {formAlert && (
                    <Box mb={4}>
                        <Alert severity={formAlert.type}>{formAlert.message}</Alert>
                    </Box>
                )}

                {props.provider === "password" && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container={true} spacing={2}>
                            <Grid size={{xs: 12}}>
                                <TextField
                                    variant="outlined"
                                    type="password"
                                    label="Password"
                                    error={!!errors.pass}
                                    helperText={errors.pass && String(errors.pass.message)}
                                    fullWidth={true}
                                    autoFocus={true}
                                    {...register("pass", {
                                        required: "Please enter your password",
                                    })}
                                />
                            </Grid>
                            <Grid size={{xs: 12}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    type="submit"
                                    disabled={pending}
                                    fullWidth={true}
                                >
                                    {!pending && <span>Submit</span>}

                                    {pending && <CircularProgress size={28}/>}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}

                {props.provider !== "password" && (
                    <AuthSocial
                        type="signin"
                        buttonText="Sign in"
                        providers={[props.provider]}
                        showLastUsed={false}
                        onAuth={() => {
                            props.callback();
                            props.onDone();
                        }}
                        onError={(message: any) => {
                            setFormAlert({
                                type: "error",
                                message: message,
                            });
                        }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}

export default ReauthModal;
