import * as React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {closeSidebar} from "./sidebarUtils";
import {isAdmin, isPremium, useAuth} from "../util/auth";
import UpgradeCard from "./UpgradeCard";
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import {EtiLogo} from "./EtiLogo";
import Paper from "@mui/material/Paper";
import {ListSubheader, Tooltip} from "@mui/material";
import {DASHBOARDS_AD, DASHBOARDS_IP, IDashboard} from "../ic3/AvailableDashboards";
import {useRouter} from "../util/Router";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import {PROFILES} from "../util/AvailableProfiles";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import MenuListItemAuth from "./MenuListItemAuth";
import {Link} from "react-router-dom";

export default function Sidebar() {

    const auth = useAuth();

    const router = useRouter();

    return (<Paper
        className="Sidebar"
        sx={{
            position: {
                xs: 'fixed', md: 'sticky',
            },
            transform: {
                xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))', md: 'none',
            },
            transition: 'transform 0.4s, width 0.4s',
            height: '100dvh',
            width: 'var(--Sidebar-width)',
            top: 0,
            p: 2,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRight: '1px solid',
            borderColor: 'divider'
        }}
        elevation={0}
    >
        <GlobalStyles
            styles={(theme) => ({
                ':root': {
                    '--Sidebar-width': '220px', [theme.breakpoints.up('lg')]: {
                        '--Sidebar-width': '240px',
                    },
                },
            })}
        />
        <Box
            className="Sidebar-overlay"
            sx={{
                position: 'fixed',
                zIndex: 9998,
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                opacity: '1',
                backgroundColor: 'var(--joy-palette-background-backdrop)',
                transition: 'opacity 0.4s',
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                    lg: 'translateX(-100%)',
                },
            }}
            onClick={() => closeSidebar()}
        />
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
            <EtiLogo height={'50px'}/>
        </Box>
        <Box
            sx={{
                minHeight: 0,
                overflow: 'hidden auto',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
        >

            <List
                sx={{
                    pb: 2
                }}
                component={"div"}
            >
                <MenuListItemAuth
                    key={"/"}
                    to="/"
                    selected={"/" === router.pathname}
                    icon={<HomeRoundedIcon/>}
                    label={"Home"}
                />
                <MenuListItemAuth
                    key={'/zoeken'}
                    to={'/zoeken'}
                    selected={'/zoeken' === router.pathname}
                    icon={<SearchRoundedIcon/>}
                    label={"Zoeken"}
                />

            </List>

            <List
                component={"div"}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Profiel
                    </ListSubheader>
                }
            >
                {
                    PROFILES.map(i =>
                        <MenuListItemAuth
                            key={i.id}
                            to={i.url}
                            selected={router.pathname.includes(i.id)}
                            icon={i.icon}
                            label={i.name}
                        />
                    )
                }

            </List>

            <List
                component={"div"}
                subheader={
                    <ListSubheader component="div">
                        Inschrijvende Partij
                    </ListSubheader>
                }
            >
                {
                    DASHBOARDS_IP.map(i => {
                        return <DashboardListItem key={i.path} dashboard={i} currentPathName={router.pathname}/>
                    })
                }
            </List>

            <List
                sx={{
                    gap: 1
                }}
                component={"div"}
                subheader={
                    <ListSubheader component="div">
                        Aanbestedende Dienst
                    </ListSubheader>
                }
            >
                {
                    DASHBOARDS_AD.map(i => {
                        return <DashboardListItem key={i.path} dashboard={i} currentPathName={router.pathname}/>
                    })
                }
            </List>

            <List
                sx={{
                    mt: 'auto', flexGrow: 0, gap: 1, mb: 2,
                }}
                component={"div"}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Instellingen
                    </ListSubheader>
                }
            >

                <MenuListItemAuth
                    key={"/iccube-admin"}
                    to="/iccube-admin"
                    selected={"/iccube-admin" === router.pathname}
                    icon={<AdminPanelSettingsRoundedIcon/>}
                    label={"Admin"}
                />

                <MenuListItemAuth
                    key={"/admin-app"}
                    to="/admin-app"
                    selected={"/admin-app" === router.pathname}
                    icon={<AnalyticsIcon/>}
                    label={"Admin dashboards"}
                />

                <MenuListItemAuth
                    key={"/expert-app"}
                    to="/expert-app"
                    selected={"/expert-app" === router.pathname}
                    icon={<AnalyticsIcon/>}
                    label={"Expert dashboard"}
                />

                <MenuListItemAuth
                    key={"/admin-audit"}
                    to="/admin-audit"
                    selected={"/admin-audit" === router.pathname}
                    icon={<DataUsageIcon/>}
                    label={"Admin audit"}
                />

                <MenuListItemAuth
                    key={"/settings/general"}
                    to="/settings/general"
                    selected={router.pathname.startsWith("/settings/")}
                    label={"Settings"}
                    icon={<SettingsRoundedIcon/>}
                />
            </List>

            {!isPremium(auth.user) && <UpgradeCard/>}

        </Box>
        {
            <Tooltip
                title={
                    isAdmin(auth.user) && <Box width={'800px'} display={'flex'} flexDirection={'column'}>
                        {Object.keys(process.env).map(k =>
                            <Typography key={k} variant={"caption"}>{k}={String(process.env[k])}</Typography>
                        )}
                    </Box>
                }
                slotProps={{tooltip: {sx: {maxWidth: '820px', width: '820px'}}}}
            >
                <Typography component={Link} to={"/release-notes"}>{process.env.REACT_APP_ETI_VERSION}</Typography>
            </Tooltip>
        }
        <Divider/>
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
            <Avatar
                variant="circular"
                component={"div"}
                sx={{width: '28px', height: '28px'}}
            />
            <Box sx={{minWidth: 0, flex: 1}}>
                <Typography sx={{fontWeight: 500}}>{auth?.user?.name}</Typography>
                <Typography
                    sx={{wordBreak: 'break-all', fontSize: '0.7rem', fontWeight: 500}}>{auth?.user?.email}</Typography>
            </Box>
            <IconButton size="small" onClick={() => auth.signout()} title="Uitloggen">
                <LogoutRoundedIcon fontSize={"small"}/>
            </IconButton>
        </Box>
    </Paper>);
}

function DashboardListItem({currentPathName, dashboard}: { currentPathName: string; dashboard: IDashboard }) {
    return <MenuListItemAuth
        to={dashboard.url}
        selected={dashboard.url === currentPathName}
        label={dashboard.name}
        icon={dashboard.icon}
    />
}