import ListItemButton from "@mui/material/ListItemButton";
import React from "react";
import {Link} from "react-router-dom";
import useAuthMatrix, {AuthMatrixResult, PossibleAppURLs} from "../hooks/useAuthMatrix";
import Box from "@mui/material/Box";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";

interface Props {
    label: string;
    to: PossibleAppURLs;
    selected: boolean;
    icon?: React.JSX.Element;
}

export default function MenuListItemAuth(props: Props) {

    const usage = useAuthMatrix(props.to);

    if (usage === AuthMatrixResult.HIDDEN) {
        return null;
    }

    return <ListItemButton
        component={Link}
        to={props.to}
        selected={props.selected}
    >
        {props.icon}
        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                    title={props.label}>{props.label}</Typography>
        {usage === AuthMatrixResult.CAN_VIEW && <Box sx={{marginLeft: "auto"}}><ProTag/></Box>}
    </ListItemButton>
}

export function ProTag() {
    return <Tooltip title={"Premium feature"}>
        <Box sx={{
            borderRadius: '5px', background: "#fedb6f", padding: '4px'
        }}
             display={'flex'} flexDirection={'row'} gap={0.5} alignItems={'center'}>
            <AutoAwesomeIcon sx={{fontSize: '12px'}}/>
        </Box>
    </Tooltip>
}