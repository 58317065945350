import React from "react";
import {useRouter} from "../util/Router";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Section from "../components/Section";
import Button from "@mui/material/Button";
import {unsubscribe} from "../util/db";
import useDbUpdate from "../hooks/useDbUpdate";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function UnsubscribePage() {
    const router = useRouter();
    const email = String(router.query.t);
    const hash = String(router.query.e);
    const campaign = String(router.query.c);
    const {formAlert, loading, handle} = useDbUpdate("You are now unsubscribed");

    const unsub = React.useCallback(() => {
        handle(unsubscribe(email, hash, campaign));
    }, [email, hash, campaign, handle]);

    return <Section>
        <Container>
            <p>Click the button below to unsubscribe for {email}</p>
            <Button variant={'contained'} onClick={unsub} color={'primary'} disabled={formAlert != null}>Unsubscribe</Button>
            {loading && <CircularProgress size={24} sx={{ml: 2}}/>}
            {formAlert != null && <Box pt={4}>
                <Alert severity={formAlert.type}>{formAlert.message}</Alert>
            </Box>}
        </Container>
    </Section>
}
